import { HEADER_HEIGHT, TABLET_WIDTH } from "@/constants/components";
import useGetViewPortResize from "@/hooks/useGetViewportResize";
import { css } from "@emotion/react";
import {
  ColorIcon,
  colors,
  getCookie,
  GLOBAL_MAX_WIDTH,
  GoiLogo,
  HStack,
  // Image,
  Spacing,
} from "goi_common";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";

import { useMemo } from "react";

import { isViewportTopState } from "@/atoms/viewportTopState";
import { VStack } from "@chakra-ui/react";

export default function Header({ onOpen }: { onOpen: () => void }) {
  const { viewportSize } = useGetViewPortResize();
  const router = useRouter();
  const isHome = router.route === "/";
  const isHeaderTransparent = router.route === "/" || router.asPath.startsWith("/sa/");
  const isViewportTop = useRecoilValue(isViewportTopState);
  const isHomeViewportTop = useMemo(() => {
    return isHeaderTransparent && isViewportTop;
  }, [isHeaderTransparent, isViewportTop]);
  const account = useMemo(() => {
    const user = getCookie("teamjang_user");
    if (user && user !== "undefined") {
      return user;
    } else return null;
  }, []);

  return (
    <>
      <HStack height={HEADER_HEIGHT} css={headerCss({ isHomeViewportTop, isHome })}>
        <HStack
          padding="0px 20px"
          width={viewportSize >= TABLET_WIDTH ? `${GLOBAL_MAX_WIDTH}px` : "100%"}
          justifyContent="space-between"
          alignItems="center"
        >
          <GoiLogo color={isHomeViewportTop ? "white" : "black"} />
          {account && account.role === "CS_MANAGER" && router.asPath !== "/parlor/search/" && (
            <HStack alignItems="end" gap={8} overflow="scroll">
              <VStack
                cursor={"pointer"}
                alignItems="center"
                as="a"
                href="/facilities/search/"
                minWidth="40px"
              >
                <ColorIcon name="search" color="black" size={24} />
                <span className="caption_12_b">장지</span>
              </VStack>
              <VStack
                minWidth="45px"
                cursor={"pointer"}
                alignItems="center"
                as="a"
                href="/parlor/search/"
              >
                <ColorIcon name="search" color="black" size={24} />
                <span className="caption_12_b">장례식장</span>
              </VStack>
              <VStack
                as="a"
                minWidth="50px"
                alignItems="center"
                cursor={"pointer"}
                href="/consult/resource/"
              >
                <ColorIcon name="list" color={colors.orange650} size={24} />
                <span className="caption_12_b">상담/자료</span>
              </VStack>
            </HStack>
          )}

          {account && account.role === "SUPERUSER" && router.asPath !== "/parlor/search/" && (
            <HStack alignItems="end" gap={8} overflow="scroll">
              <VStack
                cursor={"pointer"}
                alignItems="center"
                as="a"
                href="/facilities/search/"
                minWidth="40px"
              >
                <ColorIcon name="search" color="black" size={24} />
                <span className="caption_12_b">장지</span>
              </VStack>
              <VStack
                minWidth="45px"
                cursor={"pointer"}
                alignItems="center"
                as="a"
                href="/parlor/search/"
              >
                <ColorIcon name="search" color="black" size={24} />
                <span className="caption_12_b">장례식장</span>
              </VStack>
              <VStack
                as="a"
                minWidth="50px"
                alignItems="center"
                cursor={"pointer"}
                href="/consult/resource/"
              >
                <ColorIcon name="list" color={colors.orange650} size={24} />
                <span className="caption_12_b">상담/자료</span>
              </VStack>
              <VStack
                as="a"
                minWidth="50px"
                alignItems="center"
                cursor={"pointer"}
                href="/funeral/list/"
              >
                <ColorIcon name="icon-money-back-mono" color={colors.green450} size={24} />
                <span className="caption_12_b">비용내역</span>
              </VStack>

              <VStack
                as="a"
                minWidth="50px"
                cursor={"pointer"}
                alignItems="center"
                href="/headquarter/funeral-list/"
              >
                <ColorIcon name="drawer_cs" color={colors.green450} size={28} />
                <span className="caption_12_b">출동내역</span>
              </VStack>

              <VStack
                as="a"
                minWidth="50px"
                cursor={"pointer"}
                alignItems="center"
                href="/headquarter/cash-receipt/request/"
              >
                <ColorIcon name="icon-document-filled" color={colors.gray700} size={24} />
                <span className="caption_12_b">증빙내역</span>
              </VStack>

              <VStack
                as="a"
                minWidth="50px"
                alignItems="center"
                cursor={"pointer"}
                href="/headquarter/account-transaction/"
              >
                <ColorIcon name="list" color={colors.blue200} size={24} />
                <span className="caption_12_b gray200">경영지원</span>
              </VStack>
              {/* <VStack
                minWidth="40px"
                cursor={"pointer"}
                alignItems="center"
                onClick={() => {
                  window.open(
                    "https://dashboard.tosspayments.com/cash-receipts/tm/117235",
                    "_blank",
                  );
                }}
              >
                <Image
                  src={`https://avatars.githubusercontent.com/u/25682207?v=4`}
                  width="32px"
                  alt="토스"
                />
                <span className="caption_12_b">토스</span>
              </VStack> */}

              <VStack
                minWidth="45px"
                cursor={"pointer"}
                alignItems="center"
                onClick={() => {
                  router.push("/");
                }}
              >
                <ColorIcon name="drawer_home" color={colors.gray800} size={24} />
                <span className="caption_12_b">홈</span>
              </VStack>
            </HStack>
          )}
        </HStack>
      </HStack>
      {isHeaderTransparent || <Spacing gap={HEADER_HEIGHT} />}
    </>
  );
}

const headerCss = ({
  isHomeViewportTop,
  isHome,
}: {
  isHomeViewportTop: boolean;
  isHome: boolean;
}) =>
  css({
    borderBottom: `1px solid ${isHomeViewportTop ? colors.gray300 : colors.gray100}`,
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    position: "fixed",
    width: "100%",
    top: "0",
    backgroundColor: isHomeViewportTop ? `transparent` : colors.white,
    transition: isHome ? "background-color 0.1s linear" : "none",
    zIndex: "1000",
    userSelect: "none",
  });

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"615746e918cfa90dae7542ee523e736bbd31318d"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://43fecb33d4daec719dfc32b01ef47da9@o4508572893249536.ingest.us.sentry.io/4508572904521728",

  /** 퍼포먼스 트레이싱이란 결국 애플리케이션의 성능을 측정하는 비율을 나타내는 것 1 (최대), 이슈랑은 별개다 */
  tracesSampleRate: 0.5,

  beforeSend(event) {
    // API 호출에서 발생한 에러인 경우
    if (event.request) {
      // 요청 데이터를 extra 필드에 추가

      event.extra = {
        ...event.extra,
        requestHeaders: event.request.headers,
        requestBody: event.request.data,
        responseHeaders: event.contexts?.response?.headers,
        responseBody: event.contexts?.response?.data,
        requestUrl: event.request.url,
        requestMethod: event.request.method,
      };
    }

    return event;
  },
  /**  API 요청/응답 데이터를 브레드크럼에 추가
   *  breadcrumb란 에러 발생 전의 이벤트를 기록하여 함께 동봉하여 보내는 것으로 이해
   *
   */

  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === "xhr" || breadcrumb.category === "fetch") {
      return {
        ...breadcrumb,
        data: {
          ...breadcrumb.data,
          requestHeaders: breadcrumb.data?.requestHeaders,
          requestBody: breadcrumb.data?.requestBody,
          responseHeaders: breadcrumb.data?.responseHeaders,
          responseBody: breadcrumb.data?.responseBody,
        },
      };
    }
    return breadcrumb;
  },

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  // debug: false,
});

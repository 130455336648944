import { isLoggedInState } from "@/atoms/accountState";
import { VStack } from "goi_common";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";

import GuidebookSummaryHeader from "./GuidebookSummaryHeader";

import { useDisclosure } from "@chakra-ui/react";
import StepGuideFooter from "../common/StepGuideFooter";
import Header from "./Header";

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  const router = useRouter();
  // const { isBiggerThanMd } = useResponsive();

  const { onOpen } = useDisclosure();

  const isGuideBookSummary = useMemo(() => {
    return router.asPath.startsWith("/guidebook/summary/");
  }, [router]);

  const isHeaderHidden = useMemo(() => {
    return (
      router.route === "/login" ||
      router.route === "/login-redirect" ||
      isGuideBookSummary ||
      router.asPath.startsWith("/partners/") ||
      router.asPath.startsWith("/onthespot")
    );
  }, [router]);

  // const isNavHidden = useMemo(() => {
  //   return (
  //     router.route === "/login" ||
  //     router.route === "/login-redirect" ||
  //     router.route === "/benefit/prepare-package" ||
  //     router.pathname === "/funeral/[id]" ||
  //     router.pathname === "/review/[id]" ||
  //     router.route === "/consult/[id]" ||
  //     router.asPath.startsWith("/step-guide/") ||
  //     router.asPath.startsWith("/guide/") ||
  //     router.asPath.startsWith("/guidebook/tip") ||
  //     (router.asPath.startsWith("/parlor/") && !router.asPath.startsWith("/parlors/region")) ||
  //     (router.asPath.startsWith("/match/") &&
  //       !router.asPath.startsWith("/match/recommendations")) ||
  //     router.asPath.startsWith("/match-compare/") ||
  //     router.asPath.startsWith("/funeral-prepare/") ||
  //     router.route === "/notice/1" ||
  //     router.asPath.startsWith("/partners/")
  //   );
  // }, [router]);

  const isStepGuideFooterVisible = useMemo(() => {
    return router.route.startsWith("/guidebook");
  }, [router]);

  const isLoggedIn = useRecoilValue(isLoggedInState);

  const quotationRequestCRMDrawerOpen = () => {
    const isLoginPath = router.asPath.startsWith("/login");

    if (isLoggedIn || isLoginPath) return;
  };

  useEffect(() => {
    quotationRequestCRMDrawerOpen();
  }, [router.asPath]);

  return (
    <>
      {!isHeaderHidden && <Header onOpen={onOpen} />}
      {isGuideBookSummary && <GuidebookSummaryHeader />}
      <VStack>{children}</VStack>
      {/* {!isNavHidden && <Navigator />} */}
      {isStepGuideFooterVisible && (
        <StepGuideFooter
          text={
            <>
              장례가 처음이신가요? 합리적인 장례,
              <br />
              차근차근 알려드릴게요.
            </>
          }
          size="30px"
          src="/step-guide/facility-initiation/"
          event={{
            newGtm: {
              path: "step_guide",
              trigger_and_target: "step_guide_fixed_button",
            },
          }}
          maxWidth="100%"
          bottom={"72px"}
        />
      )}
    </>
  );
}
